@font-face {
	font-family: 'Halter';
	src: url('../assets/fonts/halter.woff') format('woff'),
	     url('../assets/fonts/halter.ttf') format('truetype');
} 

/* SCROLLBAR */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2); 
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4); 
}